
$(document).ready(function(){
  let pcLength = '130';
  let spLength = '35';
  let spLength2 = '65';
  let titleElem = $('.js-text-contorol--title');
  let txtElem = $('.js-text-contorol--text');
  let timeElem = $('.js-text-contorol--time');
  let hourElem = $('.js-text-contorol--hour');
  let tagElem = $('.js-text-contorol--tag');
  let tagList = $('.js-text-contorol--tagList');
  let subTxtElem = $('.js-text-contorol--subtext');
  let afterTxt = ' …';

  if (window.innerWidth > 768) {
    cutText(titleElem,pcLength);
    cutText(txtElem,pcLength);
  } else {
    cutText(titleElem,spLength);
    cutText(txtElem,spLength2);
    cutTextHeight(timeElem);
    cutTextHeight(hourElem);
    cutTextHeight(subTxtElem);
    tagMore();
  }

  function cutText(elem,cutLength) {
    elem.each(function () {
      let $target = $(this);
      let textLength = $target.text().length;
      let textTrim = $target.text().substr(0, (cutLength))
      if (cutLength < textLength) {
        $target.html(textTrim + afterTxt).css({ visibility: 'visible' });
      } else if (cutLength >= textLength) {
        $target.css({ visibility: 'visible' });
      }
    });
  }
  function cutTextHeight(elem) {
    elem.each(function() {
      let $target = $(this);
      let html = $target.html();
      let $clone = $target.clone();
      $clone
        .css({
          display: 'none',
          position : 'absolute',
          overflow : 'visible'
        })
        .width($target.width())
        .height('auto');

      $target.after($clone);
      while((html.length > 0) && ($clone.height() > $target.height())) {
        html = html.substr(0, html.length - 1);
        $clone.html(html + afterTxt);
      }
      $target.html($clone.html());
      $clone.remove();
    });
  }
  function tagMore() {
    tagElem.each(function () {
      let $target = $(this);
      let teHeight = $target.outerHeight();
      let tlHeight = $target.find(tagList).outerHeight();

      if (tlHeight > teHeight) {
        $target.addClass('tagList_more');
      } else {
        ;
      }
    });
  }
});

