// 商品詳細ページのスライダー
$(function () {
  $('#js-main-slider').slick({
    arrows: true,
    slidesToShow: 1,
    variableWidth: true,
    centerPadding: '30%',
    centerMode: true,
    focusOnSelect: true,
    prevArrow: '<button class="slide-arrow prev-arrow"></button>',
    nextArrow: '<button class="slide-arrow next-arrow"></button>',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          centerMode: false,
          variableWidth: false,
        }
      }
    ]
  });
});