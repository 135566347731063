export default (function ($) {
  // 検索結果ページ
  var resultMax = 34; //マックス件数（仮）
  var resultNumber = 10; //初期表示数
  $('.js-result-add').on('click', function () {
    var resultCard = function() {/*
    <div class="result_card">
      <div class="result_card__inner">
        <a chass="result_card__link" href="index.html">
          <h2 class="result_card__title js-text-contorol--title">【沖縄・青の洞窟・体験ダイビング】満足度Max★ 当日予約歓迎★GoPro★早割★コロナ対策◎手ぶらOK★高画質写真&動画無料撮影★１組貸切</h2>
        </a>
        <div class="result_card__tag js-text-contorol--tag">
          <ul class="tagList js-text-contorol--tagList">
            <li class="tagList__text">当日予約OK</li>
            <li class="tagList__text">SUP(サップ)</li>
            <li class="tagList__text">ダイビング</li>
            <li class="tagList__text">釣り</li>
            <li class="tagList__text">陶芸体験･陶芸教室</li>
            <li class="tagList__text">陶芸体験･陶芸教室</li>
          </ul>
        </div>
        <div class="result_card__block">
          <div class="result_card__block-img-short">
            <img src="../assets/images/plan/search/pic_result01.png" alt="">
          </div>
          <div class="result_card__block-detail-short">
            <div class="result_card__block-detail-txt js-text-contorol--text">＜ここは表示MAX量を制限　…で切る＞ツアー料金が無料になるかも！ ダイビングショップ和10周年記念企画 ハズレなし！お客様感謝還元大抽選会！ 2020年 ダイビングショップ和は 10周年となりました。 10年間無事故で、楽しく笑顔で営業をできているのはお客様の </div>
            <ul class="result_card__block-detail-note">
              <li class="result_card__block-detail-note-list result_card__block-detail-note-list--hour js-text-contorol--hour">1時間以内,1～2時間,2～3時間,3～4時間,4～5</li>
            </ul>
            <div class="result_card__price-detail-short">
              <div class="price-group-short">
                <div class="price-group__centerblock-short">
                  <div class="price-group__textblock">
                    <p class="price-group__pricetext">131,800<span>円 ～</span></p>
                  </div>
                </div>

              </div>
              <p class="result_card__plan pc-only">プランID：AJ00002540</p>
            </div>
          </div>
        </div>
        <p class="result_card__plan sp-only">プランID：AJ00002540</p>
        <a href="index.html" class="detail-btn">詳細</a>
      </div>
    </div>
    */                           }
    .toString()
    .match(/(?:\/\*(?:[\s\S]*?)\*\/)/)
    .pop()
    .replace(/^\/\*/, '')
    .replace(/\*\/$/, '');
    
    for (var i = 0; i < 10; i++) {
      $(resultCard).appendTo('#result-area').animate({opacity: 1}, 400, 'swing');
      resultNumber += 1;
      if (resultMax === resultNumber) {
        $('.search__more').hide();
        break;
      }
    }
  });
  
})(require('jquery'))
