var triggerClass = $(".js-search-trigger");
var modalShowClass = $(".js-modal-showSwitch");
var modalContensClass = $(".js-modalContents");
var selectRadio = $(".search-list__label");
var modalPosition;

$(triggerClass).on("click", function() {
  $(modalContensClass).css("display","none");
  var triggerClassDataVal = $(this).data("search-contents");
  var showContents = document.getElementById(triggerClassDataVal);
  $(showContents).css("display","block");
  $(modalShowClass).fadeIn(300);
  modalPosition = $(window).scrollTop();
  $("body").css("position","fixed");
  $("body").css("top",-modalPosition);
  
  //PCのスクロールバーの調整
  if (!(navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0 || navigator.userAgent.indexOf('Macintosh') > 0)) {
    var windowHeight = $(window).height();
    var pageHeight = $('body').outerHeight();
    console.log(windowHeight,pageHeight);
    if(windowHeight < pageHeight){
      $("body").css("padding-right","17px");
    }
  }
  return false;
});
$(".mv-search__contents-bg").on("click", closeModal);

$(selectRadio).on("click", function() {
  var contensDataVal = $(this).closest(modalContensClass).data("modal-contents");
  var targetId;
  if(contensDataVal === "search-area") {
    targetId = $("#js-input--area");
  } else if(contensDataVal === "search-experience") {
    targetId = $("#js-input--experience");
  }
  var elmText = $(this).text();
  targetId.val(elmText);
  closeModal();
});

function closeModal() {
  $(modalShowClass).fadeOut(300);
  $("body").css("position","relative");
  $("body").css("top",0);
  $("html, body").animate({scrollTop:modalPosition}, 0, "swing");
  $("body").css("padding-right","0");
  return false;
}