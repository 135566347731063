
if(document.getElementById('js-datepicker')!= null) {
  $.get("https://holidays-jp.github.io/api/v1/date.json", function(holidaysData) {

    var dateToday = new Date();
    var datepickerMonthProp = 1;
    if(window.innerWidth > 768) {
      datepickerMonthProp = 2;
    }
    var showAdditionalButton = function(input) {
      setTimeout(function() {
        var buttonPanel = $(input).datepicker("widget").find(".ui-datepicker-buttonpane"),
            btn = $('<button class="ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all" type="button">日程を指定しない</button>');
        btn.unbind("click").bind("click", function () {
          $.datepicker._clearDate(input);
        });
        btn.appendTo(buttonPanel);
      }, 1);
    };

    var dates = $("#js-datepicker").datepicker({
      changeMonth: true,
      numberOfMonths: datepickerMonthProp,
      duration: 300,
      beforeShowDay: function(date) {
        if (date.getDay() == 0) {
          return [true, 'day-sunday', null];
        } else if (date.getDay() == 6) {
          return [true, 'day-saturday', null];
        }

        var holidays = Object.keys(holidaysData);
        for (var i = 0; i < holidays.length; i++) {
          var holiday = new Date(Date.parse(holidays[i]));
          if (holiday.getYear() == date.getYear() &&
              holiday.getMonth() == date.getMonth() &&
              holiday.getDate() == date.getDate()) {
              return [true, 'day-holiday', null];
          }
        }
        return [true, 'day-weekday', null];
      },
      minDate: dateToday,
      onSelect: function(selectedDate) {
      var option = this.id == "from" ? "minDate" : "maxDate",
          instance = $(this).data("datepicker"),
          date = $.datepicker.parseDate(instance.settings.dateFormat || $.datepicker._defaults.dateFormat, selectedDate, instance.settings);
      dates.not(this).datepicker("option", option, date);
      },
      showButtonPanel: true,
      beforeShow: showAdditionalButton,
      onChangeMonthYear: showAdditionalButton
    });
  });
}