export default (function ($) {
  imgDeviceChange()
  $(window).on('resize', imgDeviceChange)
  function imgDeviceChange() {
    var className = '.js-img-deviceChange'
    if (window.innerWidth > 768) {
      $(className).each(function () {
        $(this).attr('src', $(this).attr('src').replace('_sp', '_pc'))
      })
    } else {
      $(className).each(function () {
        $(this).attr('src', $(this).attr('src').replace('_pc', '_sp'))
      })
    }
  }
})(require('jquery'))
