export default (function ($) {
  var weeks = ['日', '月', '火', '水', '木', '金', '土'];
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var startDate = new Date(year, month - 1, 1);
  var endDate = new Date(year, month,  0);
  var endDayCount = endDate.getDate();
  var startDay = startDate.getDay();
  
  var todayYear = date.getFullYear();
  var todayMonth = date.getMonth() + 1;
  var todayDay = date.getDate();
  
  var maxDay = 12; //何か月先のカレンダーまで表示させるかを制限する値
  var currentDay = maxDay;
  
  function setCalendar(){
    $(".calendar-month__text").text(year + "年" + month + "月");

    var dayCount = 1;
    var calendarHtml = '';

    //受付不可
    var emptyJson = [1,2,15,20];
    //リクエスト予約
    var requestJson = [6,8,21,22];
    //リクエスト予約（前払い）
    var prepaidJson = [10,15,26,28];
    //値段（1ヶ月分）
    var priceJson = [169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000,169000];

    //祝日設定
    var holidayApi = "https://holidays-jp.github.io/api/v1/" + year + "/date.json";
    var holidayJson = [];
    $.getJSON(holidayApi, (data) => {
      $.each(data, function (key) {
        var holidayDay = key.split('-');
        if(Number(holidayDay[0]) === year && Number(holidayDay[1]) === month){
          holidayJson.push(Number(holidayDay[2]));
          $(".calendar__date").each(function() {
            var dayTxt = $(this).text();
            if(Number(dayTxt) === Number(holidayDay[2])){
              $(this).parent(".calendar-week__day").addClass("calendar-week__day--holiday");
            }
          });
        }
      });
    });

    //カレンダーhtml作成
    for (var w = 0; w < 6; w++) {
      calendarHtml += '<div class="calendar-week__row">';
      for (var d = 0; d < 7; d++) {
        var calendarClass = "";
        var dayStatus = "";
        var dayPrice = "";
        if (w === 0 && d < startDay) {
          calendarHtml += '<div class="calendar-week__day calendar-week__day--empty"><div class="calendar__date"></div><div class="calendar__status"></div><div class="calendar__price"></div></div>';
        } else if (dayCount > endDayCount) {
          calendarHtml += '<div class="calendar-week__day calendar-week__day--empty"><div class="calendar__date"></div><div class="calendar__status"></div><div class="calendar__price"></div></div>';
        } else {
          if(dayCount < todayDay && todayYear === year && todayMonth === month){
            calendarClass = "calendar-week__day--soldout";
            dayStatus = "";
            dayPrice = "　";
          }else{
            if(emptyJson.indexOf(dayCount) > 0){
              dayStatus = "calendar__status--close";
              calendarClass = "calendar-week__day--soldout";
            }else if(requestJson.indexOf(dayCount) > 0){
              dayStatus = "calendar__status--request";
            }else if(prepaidJson.indexOf(dayCount) > 0){
              dayStatus = "calendar__status--prepaid";
            }else{
              dayStatus = "calendar__status--ok";
            }
            dayPrice = String(priceJson[dayCount - 1]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
          }
          calendarHtml += '<div class="calendar-week__day ' + calendarClass + '"><div class="calendar__date">' + dayCount + '</div><div class="calendar__status ' + dayStatus + '"></div><div class="calendar__price">' + dayPrice + '</div></div>';
          dayCount++;
        }
      }
      calendarHtml += '</div>';
    }
    $('.calendar-week').html(calendarHtml);
  }
  setCalendar();
  
  $(document).on('click','.js-calendar-change',function(){
    if($(this).hasClass("calendar-month__button--prev") && !$(this).hasClass("is-disable")){
      //現在より後ろに戻らないようにする
      if (currentDay < maxDay) {
        if (month === 1) {
          year += -1;
          month = 12;
        } else {
          month += -1;
        }
        currentDay += 1;
      }
    }else if($(this).hasClass("calendar-month__button--next") && !$(this).hasClass("is-disable")){
      //最大値より先に行かないようにする
      if (currentDay > 0) {
        if (month === 12) {
          year += 1;
          month = 1;
        } else {
          month += 1;
        }
        currentDay -= 1;
      }
    }
    //ボタンの非アクティブ化
    if(currentDay === maxDay){
      $(".calendar-month__button--prev").addClass("is-disable");
    }else if(currentDay === 0){
      $(".calendar-month__button--next").addClass("is-disable");
    }else{
      $(".js-calendar-change").removeClass("is-disable");
    }
    startDate = new Date(year, month - 1, 1);
    endDate = new Date(year, month,  0);
    endDayCount = endDate.getDate();
    startDay = startDate.getDay();
    setCalendar();
  });
  
  $(document).on("click",".calendar-week__day",function(){
    $(".calendar-week__day").removeClass("calendar-week__day--select");
    $(this).addClass("calendar-week__day--select");
    
    $(".contens").each(function() {
      $(this).removeClass("is-hide");
    });
    var position = $("#start-time").offset().top - 20;
    $("html, body").animate({scrollTop:position}, 500, "swing");
  });
  
  $(document).on("click",".js-pulldown-open",function(){
    if($(this).hasClass("is-active")){
      $(".js-pulldown-select").stop().slideUp();
      $(this).removeClass("is-active");
    }else{
      $(".js-pulldown-select").stop().slideDown();
      $(this).addClass("is-active");
    }
  });
  
  $(document).on("change",".js-pulldown-select input",function(){
    var selectHtml = $(this).next("label").html();
    $(".js-pulldown-open").html(selectHtml);
    $(".js-pulldown-open").addClass("is-select");
    $(".js-pulldown-open").removeClass("is-active");
    $(".js-pulldown-select").stop().slideUp();
  });
  
})(require('jquery'))
