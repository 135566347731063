export default (function ($) {
  $(function() {
    if($('#js-searchscrollCheck').length) {
      var $win = $(window),
          $main = $('main'),
          $nav = $('.js-searchscrollCheck'),
          $navbg = $('.mv-search'),
          navHeight = $nav.outerHeight(),
          navPos = $nav.offset().top,
          fixedClass = 'search__is-fixed';

      $win.on('load scroll', function() {
        var value = $(this).scrollTop();
        if (window.innerWidth > 768) {
          if ( value > navPos ) {
            $nav.addClass(fixedClass);
            $main.css('margin-top', navHeight);
            $navbg.css('padding', '0');
          } else {
            $nav.removeClass(fixedClass);
            $main.css('margin-top', '0');
            $navbg.css('padding', '15px');
          }
        }
        if (window.innerWidth < 768) {
          if ( value > navPos ) {
            $nav.addClass(fixedClass);
          } else {
            $nav.removeClass(fixedClass);
          }
        }
      });
    }
  });
})(require('jquery'))