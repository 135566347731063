export default (function ($) {

  // TOPページ（お知らせ）
  $('.js-accordion-info').on('click', function () {
    $(this).toggleClass("info-list__item--open");
    $(this).children('.info-list__text').stop().slideToggle();
  });

  // TOPページ（検索ボックス）
  $('.js-accordion-trigger').on('click', function () {
    $(this).toggleClass("search-list__title--open");
    $(this).next().stop().slideToggle();
    var indexNum;
    if($(this).closest('#search-area').length > 0){
      indexNum = $('#search-area .js-accordion-trigger').index(this);
      if($('#search-area .js-accordion-trigger').length-1 === indexNum){
        $(this).closest('ul').animate({scrollTop: ($(this).closest('ul'))[0].scrollHeight}, 400);
      }
    }else if($(this).closest('#search-experience').length > 0){
      indexNum = $('#search-experience .js-accordion-trigger').index(this);
      if($('#search-experience .js-accordion-trigger').length-1 === indexNum){
        $(this).closest('ul').animate({scrollTop: ($(this).closest('ul'))[0].scrollHeight}, 400);
      }
    }
  });

  // TOPページ（体験：もっと見る）
  $('.js-open-accordion').on('click', function () {
    $(this).toggleClass("is-open");
    $(this).next().stop().slideToggle();
    var indexNum = $('.js-open-accordion').index(this);
    if($('.js-open-accordion').length-1 === indexNum){
      $(this).closest('.c-modal__inner').animate({scrollTop: ($(this).closest('.c-modal__inner'))[0].scrollHeight}, 400);
    }
  });

  // 商品詳細ページ、Q&Aのアコーディオン
  $('.js-accordion-open').on('click', function () {
    $(this).next().slideToggle();
  });

  // STEP1ページ、プランのアコーディオン
  $(".js-accordion-open02").on('click', function () {
    var targetParentElement = $(".plan-list");
    var targetClassName = $(".plan-list__detail");
    $(this).closest(targetParentElement).find(targetClassName).stop().slideToggle();
    $(this).children().toggleClass("plan-list__button-text--close");
    $(this).toggleClass("plan-list__button--close");
    var changeTextElement = $(this).children();
    var elementStatus = changeTextElement.text();
    if (elementStatus === "詳細を見る") {
      $(changeTextElement).text("閉じる");
    } else {
      $(changeTextElement).text("詳細を見る");
    }
  });
})(require('jquery'))
