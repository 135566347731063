export default (function ($) {

  // STEP1ページ、プランのラジオボタンチェック
  $(".js-radio-check01").on('click', function () {
    var targetParentElement = $(".plan-list");
    $(this).prev(".plan-list__radio").prop("checked", true);
    $(targetParentElement).removeClass("plan-list--current");
    $(this).closest(targetParentElement).addClass("plan-list--current");
  });

  // STEP4のラジオボタンチェック
  var trigger = ".js-radio-check";
  $(trigger).on('click', radioCheck)
  function radioCheck(e) {
    var parentElement = $(this).closest(".js-radioParentElement")
    var showContens = parentElement.find(".js-toggleShowContents")
    if($(this).prev("input").val() === "no") {
      showContens.fadeOut(500);
    } else {
      showContens.fadeIn(800);
    }
  }
})(require('jquery'))



