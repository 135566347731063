var currentPosition;

$('.js-open-modal').on('click', openModal);
$('.js-close-modal').on('click', closeModal);


function openModal() {
  currentPosition = $(window).scrollTop();
  var target = $(this).data('modal-contents');
  var showContents = document.getElementById(target);
  $(showContents).fadeIn(300);
  $("body").css("position","fixed");
  $("body").css("top",-currentPosition);

  //PCのスクロールバーの調整
  if (!(navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0 || navigator.userAgent.indexOf('Macintosh') > 0)) {
    var windowHeight = $(window).height();
    var pageHeight = $('body').outerHeight();
    console.log(windowHeight,pageHeight);
    if(windowHeight < pageHeight){
      $("body").css("padding-right","17px");
    }
  }
  return false;
};


function closeModal() {
  $(this).closest('.js-modal').fadeOut(300);
  $("body").css("position","relative");
  $("body").css("top",0);
  $("html, body").animate({scrollTop:currentPosition}, 0, "swing");
  $("body").css("padding-right","0");

  return false;
};