export default (function ($) {
  if($('.js-scrollCheck').length && $('#footer').length) {
    var targetElm = $('.js-scrollCheck');
    var fixedHeight = targetElm.outerHeight();
    /* slideの一番高いサイズを取得 */
    var slideHeight = 0;
    $(".main-slider__item").each(function(){
       var thisHeight = $(this).outerHeight();
       if (thisHeight > slideHeight) {
         slideHeight = thisHeight;
       }
    });
    /* marginの調整 */
    var adjustmentHeight = 0;
    if(window.innerWidth > 768){
      adjustmentHeight = 130;
    }else{
      adjustmentHeight = 100;
    }
    var fixedPosition = $("#header").outerHeight() + $(".c-sub-header").outerHeight() + $(".breadcrumb").outerHeight() + slideHeight + $(".product-card__title").outerHeight() + $(".product-card__tag").outerHeight() + $(".product-card__detail").outerHeight() + fixedHeight + adjustmentHeight - $(window).height();
    $(window).on('scroll',function() {
      var footerElm = $("#footer").offset().top - $(window).height();
      console.log($(window).scrollTop());
      if($(window).scrollTop() > fixedPosition && $(window).scrollTop() !== 0) {
        if ($('body').css('position') !== 'fixed' && targetElm.css('position') !== 'fixed'){
          targetElm.css('position', 'fixed');
          var cssProps = {
            "position": "fiexd",
            "bottom": "0",
            "left": "0",
            "z-index": "9000"
          };
          targetElm.css(cssProps);
        }
      } else {
        targetElm.css('position', 'relative');
      }
      if ($(window).scrollTop() > footerElm) {
        targetElm.fadeOut(1000);
      } else {
        targetElm.fadeIn(500);
      }
    });
  } else if($('.js-scrollCheck').length) {
    $(function() {
      var targetElm = $('.js-scrollCheck');
      var fixedHeight = targetElm.height();
      var fixedPosition = targetElm.offset().top - $(window).height() + fixedHeight;
      $(window).on('scroll',function() {
        if ($(window).scrollTop() < fixedPosition) {
          if ($('body').css('position') !== 'fixed' && targetElm.css('position') !== 'fixed'){
            targetElm.css('position', 'fixed');
            var cssProps = {
              "position": "fiexd",
              "bottom": "0",
              "left": "0",
              "z-index": "9000"
            };
            targetElm.css(cssProps);
          }
        } else {
          targetElm.css('position', 'relative');
        }
      });
    });
  }
})(require('jquery'))