export default (function ($) {

  // TOPページのスライダー
  $(function () {
    var slider = new Swiper('#js-topSlider', {
      loop: true,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      speed: 1000,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        768: {
          spaceBetween: 0
        },
      }
    });
  });


  // 商品詳細ページ、プランの魅力スライダー
  // TOPページのスライダー
  $(function () {
    var slider = new Swiper('#js-charmSlider', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.charm-slider__button-next',
        prevEl: '.charm-slider__button-prev'
      },
      spaceBetween: 16,
      slidesPerView: 4,
      breakpoints: {
        768: {
          spaceBetween: 0,
          slidesPerView: 1,
        },
      }
    });
  });
})(require('jquery'))
