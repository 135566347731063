export default (function ($) {
  function smoothScroll() {
    $('a[href^="#"]').on('click', function () {
      var speed = 400
      var href = $(this).attr('href')
      var target = $(href == '#' || href == '' ? 'html' : href)
      var position = target.offset().top
      $('html, body').animate({ scrollTop: position }, speed, 'swing')
      return false
    })
  }
  smoothScroll()
})(require('jquery'))
