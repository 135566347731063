$(function() {
	var number,total_numner;
	var max; //合計最大数
	//ロード時
	$(window).on("load", function() {
		$(".js-num-countNum").each(function() {
      max = $(this).parent("div").data("maxcount");
			number = Number($(this).val());
			if (number === max) {
				$(this).next(".js-num-countPlus").prop("disabled", true);
			} else if (number === 0) {
				$(this).prev(".js-num-countMinus").prop("disabled", true);
			}
		});
	});
	//ダウンボタンクリック時
  $(document).on("click",".js-num-countMinus",function(){
		number = Number($(this).next(".js-num-countNum").val());
    max = $(this).parent("div").data("maxcount");
		if (number > 0) {
			$(this).next(".js-num-countNum").val(number - 1);
			if ((number - 1) === 0) {
        $(this).prop("disabled", true);
			}
      $(this).next().next(".js-num-countPlus").prop("disabled", false);
		} else {
			$(this).prop("disabled", true);
		}
	});
	//アップボタンクリック時
  $(document).on("click",".js-num-countPlus",function(){
		number = Number($(this).prev(".js-num-countNum").val());
    max = $(this).parent("div").data("maxcount");
		if (number < max) {
			$(this).prev(".js-num-countNum").val(number + 1);
			if ((number + 1) === max) {
			$(this).prop("disabled", true);
			}
			$(this).prev().prev(".js-num-countMinus").prop("disabled", false);
		} else {
			$(this).prop("disabled", true);
		}
	});
});



// STEP2
var num;
var maxNum = 0;
var minNum = 0;
var $parentClass = $('.js-passenger');
var $countNum = $('.js--passenger-countNum');
var $countPlus = $('.js-passenger-countPlus');
var $countMinus = $('.js-passenger-countMinus');
var $cloneElementParent = $("#js-duplicate");
var cloneElementSave;


// ロード時
$(window).on('load', function() {
  $countNum.val(1);
  $countMinus.prop("disabled", false);
  $countPlus.prop("disabled", false);
  cloneElementSave = $cloneElementParent.children("li:first").clone();
});


// プラスボタン押下時
$($countPlus).on('click', function() {
  maxNum = Number($(this).parent($parentClass).data('max-count'));
  num = Number($(this).prev($countNum).val());

  $(this).parent($parentClass).find($countMinus).prop("disabled", false);
  if(num < maxNum) {
    $(this).parent($parentClass).find($countNum).val(num+1);
    if(num+1 >= maxNum) {
      $(this).prop("disabled", true);
    }
  } else {
    $(this).prop("disabled", true);
  }

  // 要素複製
  var attrName01 = "participantAge";
  var attrName02 = "participantNameSei";
  var attrName03 = "participantNameMei";

  if(num > 0) {
    var cloneElement = $cloneElementParent.children("li:first").clone();
    var textNum = String(num+2);
    var attrNameNum = String(num+1);
    cloneElement.find('.participant-list__title span').text(textNum)
    cloneElement.find('.js-attr-name01').attr('name',attrName01+attrNameNum);
    cloneElement.find('.js-attr-name02').attr('name',attrName02+attrNameNum);
    cloneElement.find('.js-attr-name03').attr('name',attrName03+attrNameNum);
    $cloneElementParent.append(cloneElement);
  } else {
    $cloneElementParent.append(cloneElementSave);
  }
});


// マイナスボタン押下時
$($countMinus).on('click', function() {
  minNum = Number($(this).parent($parentClass).data('min-count'));
  num = Number($(this).next($countNum).val());

  $(this).parent($parentClass).find($countPlus).prop("disabled", false);
  if (num > minNum) {
    $(this).parent($parentClass).find($countNum).val(num-1);
    if (num-1 <= minNum) {
      $(this).prop("disabled", true);
    }
  } else {
    $(this).prop("disabled", true);
  }

  // 要素削除
  $cloneElementParent.children("li").last().remove();
});