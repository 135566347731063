export default (function ($) {

  if(document.getElementById('js-getMaps')!= null) {
    appendfileJs('//maps.googleapis.com/maps/api/js?client=gme-hiscoltd&channel=ovsop&v=quarterly');
    function appendfileJs(filename) {
      var script = document.createElement('script');
      script.src = filename;
      var firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }
    $(window).on('load', function () {
      if(document.getElementById('map01')!= null) {
        // map01
        var latlng = new google.maps.LatLng(mapLat,mapLon);
        var myOptions = {
          zoom: 18,
          center: latlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map01= new google.maps.Map(
          document.getElementById("map01"), myOptions
        );
        var marker = new google.maps.Marker({
          position: latlng,
          map: map01
        });
      }

      if(document.getElementById('map02')!= null) {
        // map02
        var latlng = new google.maps.LatLng(mapLat,mapLon);
        var myOptions = {
          zoom: 18,
          center: latlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map02= new google.maps.Map(
          document.getElementById("map02"), myOptions
        );
        var marker = new google.maps.Marker({
          position: latlng,
          map: map02
        });
      }

      if(document.getElementById('map03')!= null) {
        // // map03
        var latlng = new google.maps.LatLng(mapLat,mapLon);
        var myOptions = {
          zoom: 18,
          center: latlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map03= new google.maps.Map(
          document.getElementById("map03"), myOptions
        );
        var marker = new google.maps.Marker({
          position: latlng,
          map: map03
        });
      }
    });
  }
})(require('jquery'))



